import { axiosInstance, endpoints } from "./axiosHandler";

function createMarketingPlan(title) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(endpoints.marketing, {
        title: title
      })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function fetchMarketingPlans(filters, cancelToken) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(endpoints.marketing, {
        params: filters,
        cancelToken: cancelToken.token
      })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function fetchPendingMarketingPlans() {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(endpoints.marketing + "fetch-pending/")
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function fetchMarketingPlan(id) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(endpoints.marketing + id + "/", null)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function fetchMoreMessages(nextURL) {
  return new Promise((resolve, reject) => {
    if (nextURL) {
      axiosInstance()
        .get(nextURL, null)
        .then(response => {
          resolve(response?.data);
        })
        .catch(error => {
          reject(error);
        });
    } else {
      resolve(null);
    }
  });
}

function sendMarketingPlanBlast(id) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(endpoints.marketing + id + "/send/", null)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function addMarketingPlanMessages(planId, forms) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(endpoints.marketing + planId + "/add-forms/", {
        form: forms
      })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function removeMarketingPlanMessages(messageId) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .delete(endpoints.marketingMessage + messageId + "/", null)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function acceptMarketingMessage(id, form, message) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(endpoints.marketing + id + "/accept/", {
        form: form,
        message: message
      })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function rejectMarketingMessage(id, form, message) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(endpoints.marketing + id + "/opt-out/", {
        form: form,
        message: message
      })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export {
  createMarketingPlan,
  fetchMarketingPlans,
  fetchPendingMarketingPlans,
  fetchMarketingPlan,
  sendMarketingPlanBlast,
  addMarketingPlanMessages,
  acceptMarketingMessage,
  rejectMarketingMessage,
  removeMarketingPlanMessages,
  fetchMoreMessages
};
