<template>
  <base-modal-view title="New Marketing Plan" @cancel="cancelClicked">
    <form @submit.prevent="submitClicked" class="flex-column">
      <label class="text-field-title margin-top-075 margin-bottom-025">Title</label>
      <input
        v-model="title"
        class="text-field"
        placeholder="Enter your Title"
        name=""
        id=""
        required
        ref="pinField"
        autofocus
      />
      <primary-button class="margin-top" title="Submit" />
    </form>
  </base-modal-view>
</template>

<script>
import PrimaryButton from "../../Buttons/v2/PrimaryButton.vue";
import BaseModalView from "./BaseModalView.vue";

export default {
  name: "CreateRemarketingModal",
  components: { PrimaryButton, BaseModalView },
  data: function () {
    return {
      title: ""
    };
  },
  methods: {
    submitClicked: function () {
      this.$emit("submitted", this.title);
    },
    cancelClicked: function () {
      this.$emit("cancel");
    },
    focusPinField: function () {
      let app = this;
      setTimeout(() => {
        app.$nextTick(() => {
          app.$refs.pinField?.focus();
        });
      }, 225);
    }
  },
  mounted: function () {
    this.focusPinField();
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/base.scss";
</style>
