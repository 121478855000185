<template>
  <div>
    <div class="flex-row">
      <div class="horizontal-flex-center margin-top-1-05 flex-row" v-if="loading">
        <p class="vertical-flex-center margin-right-2 body-1">Loading More Items...</p>
      </div>
      <div v-if="!loading" class="horizontal-flex-center margin-top-1-05 flex-row">
        <p class="vertical-flex-center margin-right-2 body-1">
          {{ pageBounds.lowBound }}-{{ pageBounds.highBound }} of {{ loadedItemsCount }} loaded items
        </p>
        <primary-button
          v-if="page > 1"
          v-on:click="updatePage(page - 1)"
          class="icon"
          icon="/assets/img/icon-chevron-left.svg"
        />
        <button
          v-for="i in numPages()"
          class="icon no-button-style"
          :class="{ selected: i == page }"
          v-on:click="updatePage(i)"
          v-bind:key="i"
        >
          {{ i }}
        </button>
        <button v-if="hasMorePages" class="icon no-button-style" v-on:click="loadMore()">...</button>
        <primary-button
          v-if="hasMorePages || page < numPages()"
          class="icon"
          icon="/assets/img/icon-chevron-right.svg"
          v-on:click="updatePage(page + 1)"
        />
        <p class="vertical-flex-center margin-left-2 body-1" v-if="totalItemsCount">
          {{ totalItemsCount }} total items
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryButton from "../Buttons/v2/PrimaryButton.vue";

export default {
  name: "PaginatorBar",
  components: {
    PrimaryButton
  },
  props: {
    page: {
      type: Number,
      default: 1
    },
    pageBounds: {
      type: Object,
      default() {
        return {
          lowBound: 0,
          highBound: 20
        };
      }
    },
    loadedItemsCount: {
      type: Number,
      default: 0
    },
    totalItemsCount: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    },
    hasMorePages: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    updatePage: function (newVal) {
      this.$emit("update:page", newVal);
      if (newVal > this.numPages()) {
        this.loadMore();
      }
    },
    numPages: function () {
      return Math.ceil(this.loadedItemsCount / 20);
    },
    loadMore: function () {
      this.$emit("loadMore");
      // this.updatePage(this.numPages())
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

.selected {
  background-color: $CARD_BACKGROUND_COLOR;
}
</style>
